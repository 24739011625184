<template>
  <div class="hotmom-order">
    <div class="banner">
      <img :src="sinfo.pic.picUrl" alt />
    </div>
    <div class="container">
      <div class="title-en">New Customized Products</div>
      <div class="title-zh">主题系列</div>
      <div class="filter-content">
        <div :class="{filter:true,'pop-show':!!activeFilter}" @click.stop>
          <div class="filter-select">
            <div
              class="filter-item"
              v-for="item in filterList"
              :key="item.id"
              @click="filterClick(item)"
            >
              <span>{{item.name}}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <!--饰品分类-->
          <div class="filter-pop" v-show="activeFilter==='type'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.type == item.id}"
              v-for="item in typeList"
              :key="item.id"
              @click="tagClick('type', item.id)"
            >{{ item.name }}</div>
          </div>
          <!--主石重量-->
          <div class="filter-pop" v-show="activeFilter==='carat'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.carat === item.id}"
              v-for="item in weight"
              :key="item.id"
              @click="tagClick('carat', item.id)"
            >{{ item.ct }}</div>
          </div>
          <!--主石形状-->
          <div class="filter-pop" v-show="activeFilter==='dtype'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.dtype === item.id}"
              v-for="item in shape"
              :key="item.id"
              @click="tagClick('dtype', item.id)"
            >{{ item.name }}</div>
          </div>
          <!--价格筛选-->
          <div class="filter-pop" v-show="activeFilter==='price'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.price === item.id}"
              v-for="item in price"
              :key="item.id"
              @click="tagClick('price', item.id)"
            >{{ item.price }}</div>

            <div class="price-range">
              <input type="text" placeholder="人民币" />
              <span class="divide">-</span>
              <input type="text" placeholder="人民币" />
              <span class="btn">确定</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ware-list clearfix">
        <div
          :class="{'ware-item':true,'no-margin':!(index%3)}"
          v-for="(item, index) in wareList"
          :key="item.id"
        >
          <div class="img">
            <img :src="item.pic.picUrl" alt />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="desc">{{item.desc}}</div>
          <div class="price">{{item.minprice|toRMB}}</div>
        </div>
      </div>
      <div class="pagenation">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="12"
          hide-on-single-page
          @current-change="pageChange"
        ></el-pagination>
      </div>
      <div class="serise-title">Irisgem甄选系列</div>
      <div class="serise-sub-title">伊莉丝甄选系列的灵感源自于对非凡造型的传承、精湛工艺及对美学的不懈追求</div>
      <div class="serise-warelist">
        <div class="img" v-for="(item,index) in seriseWarelist" :key="index">
          <img :src="item.picUrl" alt />
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="title">{{sinfo.zxtitle}}</div>
          <div class="point">.....</div>
          <p>{{sinfo.zxdes}}</p>
        </div>
        <div class="right">
          <img :src="sinfo.spic.picUrl" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { GET_DIAMON_PARAM } from "../../service/other";
import {
  getTheme as GET_THEMELIST,
  getlist as GET_WAREList
} from "../../service/api";

export default {
  name: "HotMom",
  components: {},
  data() {
    return {
      activeFilter: "",
      seachData: { type: "", carat: "", dtype: "", price: "" },
      filterList: [
        { id: "type", name: "饰品类别" },
        { id: "carat", name: "主石重量" },
        { id: "dtype", name: "主石形状" },
        { id: "price", name: "价格筛选" }
      ],
      typeList: [
        { id: 1, name: "项链" },
        { id: 2, name: "戒指" },
        { id: 3, name: "手链" },
        { id: 4, name: "耳饰" },
        { id: "diamondorder", name: "裸钻定制" }
      ],
      weight: [
        { id: 1, ct: "0.3ct" },
        { id: 2, ct: "0.5ct" },
        { id: 3, ct: "0.8ct" },
        { id: 4, ct: "1ct" },
        { id: 5, ct: "2ct" }
      ], //圆形(0) 公主方形(1) 祖母绿形(2) 雷地恩形(3) 枕垫形(4) 梨形(5) 椭圆形(6) 心形(7)
      shape: [
        { id: 0, name: "圆形" },
        { id: 1, name: "公主方形" },
        { id: 2, name: "祖母绿形" },
        { id: 3, name: "雷迪恩形" },
        { id: 4, name: "垫形" },
        { id: 5, name: "梨形" },
        { id: 6, name: "阿斯切形" },
        { id: 8, name: "心形" }
      ],
      price: [
        { id: 1, price: "5000以下" },
        { id: 2, price: "5001-10000" },
        { id: 3, price: "10001-20000" },
        { id: 4, price: "20001-40000" },
        { id: 5, price: "40000以上" }
      ],
      wareList: [],
      seriseWarelist: [],
      pagenation: {
        start: 0,
        size: 12
      },
      total: 0,
      sinfo: { pic: {}, spic: {} }
    };
  },
  computed: {
    seriesId() {
      return this.$route.query.id || "";
    }
  },
  watch: {
    seriesId: {
      immediate: true,
      handler(newVal) {
        if (newVal !== "") {
          this.getSeries();
        }
      }
    }
  },
  created() {
    this.bind();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.bodyClick);
  },
  methods: {
    bind() {
      document.body.addEventListener("click", this.bodyClick);
    },
    getSeries() {
      GET_THEMELIST({ themeid: this.seriesId, start: 0, size: 12 }).then(
        res => {
          this.sinfo = res.attachment.sinfo;
          this.seriseWarelist = res.attachment.sinfo.zxpics;
          this.wareList = res.attachment.datas;
        }
      );
    },
    getWareList() {
      GET_WAREList({ ...this.seachData, ...this.pagenation }).then(res => {
        if (res.status === 200) {
          this.total = res.attachment.total;
          this.wareList = res.attachment.datas;
        }
        console.log("getWareList", res);
      });
    },
    bodyClick() {
      this.activeFilter = "";
    },
    // getDiamonPramas() {
    //   GET_DIAMON_PARAM().then(res => {
    //     if (res.status === 200) {
    //       console.log(res);
    //     }
    //   });
    // },
    filterClick(item) {
      this.activeFilter = item.id;
    },
    tagClick(type, id) {
      if (id === "diamondorder") {
        return this.orderDimond();
      }
      let val = this.seachData[type];
      this.seachData[type] = val === id ? "" : id;
      this.getWareList();
    },
    orderDimond() {
      this.$router.push({
        path: "/diamondorder"
      });
    },
    pageChange(val) {
      this.pagenation.start = this.pagenation.size * (val - 1);
      this.getWareList();
    }
  }
};
</script>
<style lang="less" scoped>
.hotmom-order {
  .banner {
    width: 100%;
    // height: 516px;
    margin: 0 auto 140px;
    img {
      width: 100%;
      // height: 100%;
    }
  }
  .container {
    width: 1320px;
    margin: 0 auto 200px;
    .title-en {
      font-size: 40px;
      color: #d2d2d2;
      line-height: 1;
      text-align: center;
      margin-bottom: 30px;
    }
    .title-zh {
      font-size: 44px;
      color: #666;
      line-height: 1;
      margin-bottom: 90px;
      text-align: center;
    }
    .filter.pop-show {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .filter-content {
      position: relative;
      width: 100%;
      height: 80px;
    }
    .filter {
      width: 100%;
      color: #000;
      position: absolute;
      top: 0;
      left: 0;
      .filter-item {
        cursor: pointer;
        span {
          padding-right: 40px;
        }
      }
      .filter-select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cfcfcf; /*no*/
        line-height: 80px;
        padding: 0 70px;
        font-size: 22px;
      }
      .filter-pop {
        // position: absolute;
        width: 100%;
        left: 0;
        top: 80px;
        padding: 30px 0 10px;
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        > div {
          font-size: 22px;
          width: 20%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-bottom: 20px;
        }
        .price-range {
          width: 100%;
          text-align: left;
          padding-left: 70px;
          input {
            width: 250px;
            height: 40px;
            border: none;
            outline: none;
            background: #f5f5f5;
            padding: 0 18px;
            font-size: 16px;
          }
          .divide {
            padding: 0 12px;
          }
          .btn {
            padding: 0 34px;
            background: #921d22;
            color: #fff;
            margin-left: 54px;
            display: inline-block;
            line-height: 40px;
            font-size: 16px;
          }
        }
        .filter-pop-item.actived {
          color: #921d22;
        }
        .filter-pop-item {
          cursor: pointer;
        }
      }
    }
    .ware-list {
      // overflow: hidden;
      // display: flex;
      // flex-wrap: wrap;
      .ware-item.no-margin {
        margin-left: 0;
      }
      .ware-item {
        width: 396px;
        height: 493px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        // flex: 1;
        float: left;
        margin-left: 66px;
        margin-top: 60px;
        text-align: center;
        .img {
          width: 100%;
          height: 324px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .title {
          line-height: 60px;
          font-size: 22px;
        }
        .desc {
          line-height: 40px;
          font-size: 17px;
          padding: 0 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price {
          line-height: 34px;
          font-size: 14px;
          color: #666;
          margin-top: 20px;
          line-height: 36px;
        }
      }
    }
    .pagenation {
      display: flex;
      justify-content: center;
      padding: 50px 0;
    }
    .serise-title {
      margin-top: 100px;
      margin-bottom: 14px;
      text-align: center;
      color: #666;
      font-size: 44px;
    }
    .serise-sub-title {
      line-height: 84px;
      text-align: center;
      color: #000;
      font-size: 22px;
    }
    .serise-warelist {
      display: flex;
      justify-content: center;
      .img.first-child {
        margin-left: 0;
      }
      .img {
        width: 209px;
        height: 209px;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    .bottom {
      margin: 100px 0 200px;
      display: flex;
      justify-content: space-between;
      height: 480px;
      .left {
        width: 487px;
        .title {
          font-size: 44px;
          color: #666;
        }
        .point {
          font-size: 60px;
          line-height: 20px;
          margin-bottom: 50px;
          color: #999;
        }
        p {
          margin-bottom: 32px;
          line-height: 32px;
        }
      }
      .right {
        width: 758px;
        height: 100%;
        img {
          // width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
</style>